import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { EMainRoutes } from '../types/enums';
import { AdminContext } from '../providers/AdminProvider';

const ProtectedRoute = ({ component: ProtectedScreen, ...rest }: RouteProps) => {
  const { state } = useContext(AdminContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        state.isAuth ? (
          //@ts-ignore
          <ProtectedScreen {...props} />
        ) : (
          <Redirect to={EMainRoutes.LOGIN} />
        )
      }
    />
  );
};

export default ProtectedRoute;
