import React from 'react';

export const withProvider = (Component: React.ReactNode, providers: React.ReactNode[]) => {
  const WrapperComponent = (props: any) => {
    const renderComponents = (renderMap: any) => {
      const Components = renderMap.reverse().reduce((ComponentSoFar: any, Provider: any) => {
        return () => (
          <Provider>
            <ComponentSoFar {...props} />
          </Provider>
        );
      }, Component); // initial value, first component

      return <Components />;
    };

    return renderComponents(providers);
  };

  return WrapperComponent;
};
