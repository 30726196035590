import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import { images } from '../../assets/images';
import { generateGalleryItemSize } from '../../utils/helperUtils';
import GalleryItem from '../components/GalleryItem';
import { GalleryContext } from '../../providers/GalleryProvider';
import { useGallery } from '../../hooks/useGallery';
import { Gallery } from '../../types/generalTypes';

// const gallery = [
//   {
//     id: 1,
//     images: [{ url: images.landing }, { url: images.landing }, { url: images.landing }],
//     title: 'kazkoks',
//   },

export const GallerySegment = () => {
  const { gallery } = useGallery();
  const { prepareGallery } = useContext(GalleryContext);

  const renderGalleryItem = (item: Gallery, index: number) => {
    const size = generateGalleryItemSize(index);
    return (
      <GalleryItem key={item._id} item={item} size={size} onClick={prepareGallery} index={index} />
    );
  };

  return <GalleryContainer>{gallery?.map(renderGalleryItem)}</GalleryContainer>;
};

const GalleryContainer = styled.div`
  width: 100%;
  min-height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
