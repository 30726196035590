import React, { createContext, useReducer, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EMainRoutes } from '../types/enums';
import { api } from '../Api';

interface AuthState {
  isAuth: boolean;
  token: string | null;
}

enum EUserActions {
  SET_USER = 'SET_USER',
  LOGOUT = 'LOGOUT',
}

const initialState: AuthState = {
  isAuth: false,
  token: null,
};

export const AdminContext = createContext({
  state: initialState,
  loginUser: (formData: any) => {},
  logout: () => {},
});

const reducer = (state: AuthState, action: any) => {
  switch (action.type) {
    case EUserActions.SET_USER:
      return { ...state, isAuth: true, token: action.token };
    case EUserActions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const AdminProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setUser(token);
    }
  }, []);

  const setUser = (token: string) => {
    dispatch({
      type: EUserActions.SET_USER,
      token,
    });
    api.setToken(token);
    history.push(EMainRoutes.ADMIN);
  };

  const loginUser = async (formData: any) => {
    try {
      const { data } = await api.login(formData);
      setUser(data.token);
      localStorage.setItem('token', data.token);
    } catch (e) {
      console.log(e);
    }
  };

  const logout = useCallback(() => {
    dispatch({ type: EUserActions.LOGOUT });
    history.push(EMainRoutes.MAIN);
    localStorage.removeItem('token');
  }, []);

  return (
    <AdminContext.Provider value={{ state, loginUser, logout }}>{children}</AdminContext.Provider>
  );
};
