import React, { useCallback, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Portal from '@material-ui/core/Portal';
import DehazeRoundedIcon from '@material-ui/icons/DehazeRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Transition } from 'react-transition-group';
import { EAnimationState } from '../../types/enums';
import { EConsts } from '../../config/staticData';
import { mobileMenuTransition } from '../../config/themes';
import { EColors, EOpacity } from '../../config/styles';
import { useLockBodyScroll } from '../../hooks';

interface IProps {
  renderMenu: () => React.ReactChild;
}

export const MobileMenu = ({ renderMenu }: IProps) => {
  const [isMobileMenuActive, setMobileMenuActive] = useState(false);
  useLockBodyScroll(isMobileMenuActive);

  const handleOpenMobileMenu = useCallback(() => {
    setMobileMenuActive((prev) => !prev);
  }, []);

  const MobileIcon = isMobileMenuActive ? CloseRoundedIcon : DehazeRoundedIcon;
  return (
    <>
      <BreadCrumpContaioner>
        <MobileIcon onClick={handleOpenMobileMenu} fontSize={'large'} color={'inherit'} />
      </BreadCrumpContaioner>
      <Portal>
        <Transition in={isMobileMenuActive} timeout={EConsts.TIMEOUT}>
          {(s) => {
            const isEntered = s === EAnimationState.ENTERED;
            return (
              <ThemeProvider theme={isEntered ? mobileMenuTransition : {}}>
                <Menu>{isEntered && renderMenu()}</Menu>
              </ThemeProvider>
            );
          }}
        </Transition>
      </Portal>
    </>
  );
};

const BreadCrumpContaioner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${EColors.GREY};
  z-index: 100;
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: ${({ theme }) => theme.opacity ?? 0};
  height: ${({ theme }) => theme.height ?? '0px'};
  transition: 0.3s;
  background-color: ${EColors.BLACK + EOpacity.OPAC_90};
  z-index: 50;
  padding-top: 60px;
`;
