import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { WORKS, Item } from '../../config/contentData';
import { workSegmentTheme as theme } from '../../config/themes';
import { EColors, EFonts } from '../../config/styles';
import { ResponsiveLayoutContext } from '../../providers/ResponsiveLayoutProvider';

export const WorksSegment = () => {
  const { isTablet } = useContext(ResponsiveLayoutContext);

  const renderItem = ({ label, icon }: Item, index: number) => {
    return (
      <ItemContainer>
        <ImageContainer>
          <Image src={icon} />
        </ImageContainer>
        <DetailLabel>{label}</DetailLabel>
      </ItemContainer>
    );
  };

  return (
    <Container>
      <ThemeProvider theme={isTablet ? theme.mobile : theme.desktop}>
        {WORKS.map(renderItem)}
      </ThemeProvider>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ theme }) => theme.direction};
  flex-wrap: wrap;
  justify-content: space-around;
  padding: ${({ theme }) => theme.padding};
  /* border: 1px solid black; */
`;

const ItemContainer = styled.div`
  width: 40%;
  min-width: 350px;
  height: 120px;
  margin: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const DetailLabel = styled.p`
  color: ${EColors.TEXT_GREY};
  font-size: ${({ theme }) => theme.fontSize}px;
  font-family: ${EFonts.default};
  user-select: none;
  cursor: default;
`;

const ImageContainer = styled.div`
  width: 70px;
  height: 70px;
  padding: 0 40px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
