import React from 'react';
import ProtectedRoute from '../components/ProtectedRoute';
import GallerySegment from '../screens/admin/segments/GallerySegment';
import SettingsSegment from '../screens/admin/segments/SettingsSegment';
import { EAdminRoutes } from '../types/enums';

const AdminRoutes = () => {
  return (
    <>
      <ProtectedRoute component={GallerySegment} path={EAdminRoutes.GALLERY} />
      <ProtectedRoute component={SettingsSegment} path={EAdminRoutes.SETTINGS} />
    </>
  );
};

export default AdminRoutes;
