import { ImageAspectRatioSharp } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import useSWR from 'swr';
import { EAPIRoutes, api } from '../Api';
import { WORKS } from '../config/contentData';
import { Gallery, Response } from '../types/generalTypes';

interface Options {
  filter?: any;
}

export const useGallery = (options?: Options) => {
  const { data, error } = useSWR<Response<Gallery[]>, any>(EAPIRoutes.GET_GALLERY, api.getGallery);

  const getGalleryById = useCallback(
    (id: number) => {
      let images: any = [];
      const title = WORKS[id].label;
      if (data?.data) {
        data.data.forEach((item) => {
          item.images.forEach((image) => {
            if (image.categories.includes(id)) {
              images.push(image);
            }
          });
        });
      }

      return !isEmpty(images) ? { images, title } : null;
    },
    [data],
  );

  return { gallery: data?.data, getGalleryById };
};
