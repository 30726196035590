import React, { useCallback } from 'react';
import styled from 'styled-components';
import { $enum } from 'ts-enum-util';
import {
  Select,
  FormControl,
  InputLabel,
  Chip,
  MenuItem,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RemoveIcon from '@material-ui/icons/Close';
import { strings } from '../../../config/strings';
import { Image, SelectCategoriesCE, ImageData } from '../../../types/generalTypes';
import { EImageFilterType } from '../../../types/enums';

interface Props {
  data: Image;
  removeImage: (imageID: string) => void;
  updateImage: (imageData: ImageData, imageID: string) => void;
}

const ImageRow = ({ data, removeImage, updateImage }: Props) => {
  const changeCategoriesHandler = useCallback(({ target }: SelectCategoriesCE) => {
    updateImage({ categories: target.value as number[] }, data._id);
  }, []);

  const removeImageHandler = useCallback((imageID: string) => () => removeImage(imageID), []);

  const toggleImageTypeHandler = useCallback(
    (filterType) => () => updateImage({ filterType }, data._id),
    [],
  );

  const renderValue = (selected: unknown) => {
    return (selected as number[]).map((value) => (
      <Chip key={value} label={strings.landingDetails[value]} />
    ));
  };

  const renderCategories = (label: string, i: number) => (
    <MenuItem value={i} key={label}>
      {label}
    </MenuItem>
  );

  const renderFilterItem = useCallback(
    (filterType) => {
      const isChecked = data.filterType === filterType;
      return (
        <TableCell size={'small'} padding={'none'} align={'center'}>
          <Checkbox
            onChange={toggleImageTypeHandler(!isChecked ? filterType : null)}
            checked={isChecked}
          />
        </TableCell>
      );
    },
    [data],
  );

  return (
    <TableRow key={data._id}>
      <TableCell padding={'none'}>
        <CardImageContainer>
          {data.thumb ? (
            <CardImage src={data.thumb.url} />
          ) : (
            <Skeleton variant={'rect'} width={105} height={60} />
          )}
        </CardImageContainer>
      </TableCell>
      <TableCell size={'small'} style={{ width: '100%' }}>
        <FormControl fullWidth={true} variant="outlined" size={'small'}>
          <InputLabel>{strings.gallery.imageCategoryLabel}</InputLabel>
          <Select
            value={data.categories}
            multiple={true}
            label={strings.gallery.imageCategoryLabel}
            fullWidth={true}
            renderValue={renderValue}
            onChange={changeCategoriesHandler}
          >
            {strings.landingDetails.map(renderCategories)}
          </Select>
        </FormControl>
      </TableCell>
      {$enum(EImageFilterType).map(renderFilterItem)}
      <TableCell size={'small'}>
        <IconButton size={'small'}>
          <StyledRemoveIcon
            color={'error'}
            fontSize={'default'}
            onClick={removeImageHandler(data._id)}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ImageRow;

const CardImageContainer = styled.div`
  width: 105px;
  height: 60px;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  align-self: center;
  padding: 6px;
`;
