import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';

export const images = {
  logo: require('./logo.png'),
  logoDark: require('./logo-dark.png'),
  landing: [require('./landing/1.jpg'), require('./landing/2.jpg'), require('./landing/3.jpg')],
  works: {
    deg: require('./works/deg451.png'),
    electricity: require('./works/electricity.png'),
    faucet: require('./works/faucet.png'),
    hammer: require('./works/hammer.png'),
    paintRoller: require('./works/paint-roller.png'),
    puttyKnife: require('./works/putty-knife.png'),
    screwdriver: require('./works/screwdriver.png'),
  },
};

library.add(faPhoneAlt, faEnvelope, faFacebook, faYoutube);
