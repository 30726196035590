import React, { useContext } from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableHead, TableRow, InputLabel } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import DropZone from 'react-dropzone';
import { isEmpty } from 'lodash';
import { strings } from '../../../config/strings';
import ImageRow from './ImageRow';
import { GalleryManagamentContext } from '../../../providers/GalleryManagamentProvider';
import { Image } from '../../../types/generalTypes';

const HEADER_CELLS = ['', 'Kategorija', 'Prieš', 'Po', ''];

export const PhotosSection = () => {
  const { gallery, removeImage, updateImage, setImages } = useContext(GalleryManagamentContext);

  const renderHeaderCell = (label: string) => {
    return <TableCell size={'small'}>{label}</TableCell>;
  };

  const renderImageRow = (item: Image) => {
    return (
      <ImageRow key={item._id} data={item} removeImage={removeImage} updateImage={updateImage} />
    );
  };

  console.log(gallery.images);

  return (
    <>
      <PickerContainer>
        <DropZone onDrop={setImages}>
          {({ getRootProps, getInputProps }) => (
            <DropDownArea {...getRootProps()}>
              <input {...getInputProps()} />
              <InputLabel>{strings.gallery.upload}</InputLabel>
              <UploadIcon color={'disabled'} fontSize={'large'} />
            </DropDownArea>
          )}
        </DropZone>
      </PickerContainer>
      <ImagesContainer>
        {!isEmpty(gallery.images) && (
          <Table>
            <TableHead>
              <TableRow>{HEADER_CELLS.map(renderHeaderCell)}</TableRow>
            </TableHead>
            <TableBody>{gallery.images.map(renderImageRow)}</TableBody>
          </Table>
        )}
      </ImagesContainer>
    </>
  );
};

const PickerContainer = styled.div`
  width: 100%;
  padding: 20px 0;
`;

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const DropDownArea = styled.div`
  width: 100%;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-style: dashed;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 16px;
`;

const UploadIcon = styled(CloudUploadOutlinedIcon)`
  padding: 10px;
`;
