import { Tab, Form } from '../types/generalTypes';
import { strings } from '../config/strings';
import { EChangePassKeys } from '../types/enums';
import { TextFieldProps } from '@material-ui/core';

export enum EConsts {
  TIMEOUT = 300,
}

export const TABS: Tab[] = [
  { label: strings.adminMenu.gallery, path: '/admin/gallery' },
  { label: strings.adminMenu.settings, path: '/admin/settings' },
  { label: strings.adminMenu.logout, path: null },
];

export const FORM_CONFIG: TextFieldProps = {
  size: 'small',
  variant: 'outlined',
  margin: 'dense',
  fullWidth: true,
};

export const CHANGE_PASS_FORM: Form<EChangePassKeys>[] = [
  {
    label: strings.settings.changePass.oldPassword,
    name: EChangePassKeys.OLD_PASSWORD,
  },
  {
    label: strings.settings.changePass.newPassword,
    name: EChangePassKeys.NEW_PASSWORD,
  },
  {
    label: strings.settings.changePass.reNewPassword,
    name: EChangePassKeys.RE_NEW_PASSWORD,
  },
];
