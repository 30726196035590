import React, { useCallback, useContext, useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
// import CropFreeRoundedIcon from '@material-ui/icons/CropFreeRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import Portal from '@material-ui/core/Portal';
import { EColors, EOpacity } from '../config/styles';
import { Gallery, GalleryImage } from '../types/generalTypes';
import { galleryImageTheme } from '../config/themes';
import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';
import { ResponsiveLayoutContext } from '../providers/ResponsiveLayoutProvider';

interface Props {
  data?: Gallery;
  onClose: () => void;
  open?: boolean;
}

const GalleryModal = React.forwardRef(({ data, onClose, open }: Props, _) => {
  const [fullScreen, setFullScreen] = useState(false);
  const { isTablet } = useContext(ResponsiveLayoutContext);
  const imageTheme = galleryImageTheme.full;

  const handleFullScreen = useCallback(() => setFullScreen((prev) => !prev), []);

  const renderImage = useCallback(
    ({ original }: GalleryImage, i: number) => {
      return (
        <ImageContainer>
          <Image key={original.name} src={original.url} />
        </ImageContainer>
      );
    },
    [imageTheme],
  );

  return (
    <ThemeProvider theme={imageTheme}>
      <Container>
        {open && (
          <Portal>
            <Wrapper isTablet={isTablet}>
              {isTablet && (
                <>
                  <CloseControl onClick={onClose}>
                    <CloseRoundedIcon fontSize={'large'} htmlColor={EColors.WHITE} />
                  </CloseControl>
                  {/* <ForwardControl onClick={onClose}>
                    <ArrowForwardIcon fontSize={'large'} htmlColor={EColors.WHITE} />
                  </ForwardControl>
                  <BackControl onClick={onClose}>
                    <ArrowBackIcon fontSize={'large'} htmlColor={EColors.WHITE} />
                  </BackControl> */}
                </>
              )}
            </Wrapper>
          </Portal>
        )}
        {data && (
          <AutoRotatingCarousel
            hideArrows={false}
            open={open}
            onClose={onClose}
            mobile={isTablet || fullScreen}
          >
            {data.images.map(renderImage)}
          </AutoRotatingCarousel>
        )}
      </Container>
    </ThemeProvider>
  );
});

export default GalleryModal;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div<{ isTablet: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1301;
  height: 30px;
`;

const Controll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1302;
  border-radius: 50%;
  background-color: ${EColors.BLACK + EOpacity.OPAC_20};
`;

const CloseControl = styled(Controll)`
  top: 16px;
  right: 16px;
`;

const ForwardControl = styled(Controll)`
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

const BackControl = styled(Controll)`
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
`;

// const FullScreenControl = styled(Controll)`
//   bottom: -40px;
//   right: -100px;
// `;

const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: scale;
`;

const Title = styled.p`
  font-size: 24px;
  color: ${EColors.GREY};
  position: absolute;
  top: 20px;
  left: 0;
`;
