import { withStyles } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';
// Material ui restyled components

export const MenuItem = withStyles({
  root: {
    textTransform: 'none',
    fontSize: 18,
  },
})(Tab);
