import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { images } from '../../assets/images';
import { EColors, EOpacity, EStyles, EFonts } from '../../config/styles';
import { strings } from '../../config/strings';
import { CONTACTS, Contact } from '../../config/contentData';
import ContactItem from './ContactItem';
import ResponsiveLayout from '../../components/ResponsiveLayout';
import { ResponsiveLayoutContext } from '../../providers/ResponsiveLayoutProvider';
import { AdminContext } from '../../providers/AdminProvider';
import { useHistory, useLocation } from 'react-router-dom';
import { EMainRoutes } from '../../types/enums';
import { MobileMenu } from './MobileMenu';

const Header = () => {
  const { isMobile, isTablet } = useContext(ResponsiveLayoutContext);
  const { state } = useContext(AdminContext);
  const location = useLocation();
  const history = useHistory();

  const handleAdminClick = () => {
    let route = EMainRoutes.MAIN;
    if (location.pathname === EMainRoutes.MAIN) {
      route = EMainRoutes.ADMIN;
    }
    history.push(route);
  };

  const adminLinkLabel = location.pathname === EMainRoutes.MAIN ? 'Admin' : 'Home';

  const renderItem = useCallback(
    (item: Contact, index: number) => {
      const isLast = index === CONTACTS.length - 1;
      return (
        <ContactItem
          key={index}
          showLabel={!isTablet || isMobile}
          isLast={isLast}
          data={item}
          flat={isMobile}
        />
      );
    },
    [isTablet, isMobile],
  );

  const renderMenu = useCallback(() => {
    return (
      <>
        {CONTACTS.map(renderItem)}
        {state.isAuth && <AdminLink onClick={handleAdminClick}>{adminLinkLabel}</AdminLink>}
      </>
    );
  }, [state.isAuth, adminLinkLabel, handleAdminClick, renderItem]);

  return (
    <HeaderContainer>
      <Content>
        <LogoContainer>
          <Logo src={images.logo} />
          <LogoTitle>{strings.logoTitle}</LogoTitle>
        </LogoContainer>
        {!isMobile ? <Contacts>{renderMenu()}</Contacts> : <MobileMenu renderMenu={renderMenu} />}
      </Content>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${EColors.BLACK + EOpacity.OPAC_70};
  box-shadow: 0px 0px 10px 12px ${EColors.BLACK + EOpacity.OPAC_70};
  z-index: 100;
`;

const Content = styled(ResponsiveLayout)`
  padding: 0 ${EStyles.APP_PADDING}px;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Logo = styled.img`
  width: 50px;
  padding-right: 16px;
  z-index: 100;
`;

const LogoTitle = styled.h1`
  font-size: 24px;
  color: ${EColors.GREY};
  font-family: ${EFonts.default};
  font-weight: 700;
  cursor: default;
  user-select: none;
  z-index: 100;
`;

const Contacts = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AdminLink = styled.p`
  color: ${EColors.GREY};
`;
