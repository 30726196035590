import { createGlobalStyle } from 'styled-components';

import bold from '../assets/fonts/ComicNeue-Bold.ttf';
import regular from '../assets/fonts/ComicNeue-Regular.ttf';

export enum EFonts {
  default = 'Comic Neue',
}

export enum ELabelTypes {
  HEADER,
  LABEL,
}

export enum EColors {
  WHITE = '#ffffff',
  BLACK = '#000000',
  GREY = '#B1B1AB',
  TEXT_GREY = '#333333',
  // RED = ''
}

export enum EStyles {
  SCREEN_WIDTH = window.screen.width,
  SCREEN_HEIGHT = window.screen.height,
  SCREEN_RATIO = window.screen.width / window.screen.height,
  APP_WIDTH = 1100,
  APP_PADDING = 16,
}

export enum EOpacity {
  OPAC_10 = '1A',
  OPAC_20 = '33',
  OPAC_40 = '66',
  OPAC_50 = '80',
  OPAC_70 = 'B3',
  OPAC_85 = 'D9',
  OPAC_90 = 'E6',
  OPAC_100 = 'FF',
}

export enum EScreenBreakpoints {
  DESKTOP,
  TABLET_V,
  TABLET_H,
  MOBILE,
}

export const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: ${EFonts.default};
        src: local(${EFonts.default}), local(${EFonts.default}),
        url(${regular}) format('ttf'),
        url(${bold}) format('ttf');
    }
`;
