import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ResponsiveLayoutContext } from '../../providers/ResponsiveLayoutProvider';
import { images } from '../../assets/images';
import { strings } from '../../config/strings';
import { EColors, EFonts } from '../../config/styles';
import { companySegmentTheme as theme } from '../../config/themes';

export const CompanySegment = () => {
  const { isMobile } = useContext(ResponsiveLayoutContext);

  return (
    <ThemeProvider theme={isMobile ? theme.mobile : theme.desktop}>
      <Container>
        <CompanyContainer>
          <Title>{strings.companySegment.title}</Title>
          <Content>{strings.companySegment.content}</Content>
        </CompanyContainer>
        <LogoContainer>
          <Logo src={images.logoDark} />
          <LogoTitle>{strings.companySegment.logoTitle}</LogoTitle>
        </LogoContainer>
      </Container>
    </ThemeProvider>
  );
};

const Container = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.padding};
  display: flex;
  flex-direction: ${({ theme }) => theme.direction};
  box-sizing: border-box;
  justify-content: space-around;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 80%;
`;

const LogoTitle = styled.h2`
  padding-top: 16px;
  font-size: 20px;
  color: ${EColors.TEXT_GREY};
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-family: ${EFonts.default};
`;

const CompanyContainer = styled.div`
  width: ${({ theme }) => theme.compWidth};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-bottom: ${({ theme }) => theme.paddingBottom}px;
`;

const Title = styled.h2`
  padding: 16px;
  font-size: 40px;
  color: ${EColors.TEXT_GREY};
  font-weight: 700;
  font-family: ${EFonts.default};
`;

const Content = styled.h3`
  font-size: 20px;
  color: ${EColors.TEXT_GREY};
  font-family: ${EFonts.default};
  align-self: center;
  width: 80%;
`;
