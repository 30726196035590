import React, { useContext } from 'react';
import styled from 'styled-components';
import { ResponsiveLayoutContext } from '../../providers/ResponsiveLayoutProvider';
import ResponsiveLayout from '../../components/ResponsiveLayout';
import Header from '../components/Header';
import HomeImageCarousel from '../components/HomeImageCarousel';
import WorkDetailsBox from '../components/WorkDetailsBox';

export const LandingPage = () => {
  const { isTablet } = useContext(ResponsiveLayoutContext);
  // const { isActive, mouseHover } = useHover();
  // const [showYoutube, setShowYoutube] = useState(false);

  // const toggleYoutubeHndler = () => setShowYoutube((prev) => !prev);

  // const renderButton = (state: EAnimationState) => {
  //   const isEntered = state === EAnimationState.ENTERED;
  //   return (
  //     <ThemeProvider theme={() => (isEntered ? youtubeButtonTransitionTheme : {})}>
  //       <YoutubeButton
  //         onMouseEnter={mouseHover(true)}
  //         onMouseLeave={mouseHover(false)}
  //         onClick={toggleYoutubeHndler}
  //         // href={'http://youtube.com'}
  //         // target={'_blank'}
  //       >
  //         <YoutubeTitle>{strings.youtube}</YoutubeTitle>
  //         <YouTubeIcon
  //           style={{ fontSize: 40, color: isEntered ? 'red' : EColors.GREY, transition: '0.3s' }}
  //         />
  //       </YoutubeButton>
  //     </ThemeProvider>
  //   );
  // };

  return (
    <Container>
      <HomeImageCarousel />
      <LandingContent>
        <Header />
        <ResponsiveContent>
          <RightContainer>{!isTablet && <WorkDetailsBox />}</RightContainer>
          <LeftContainer />
        </ResponsiveContent>
      </LandingContent>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const LandingContent = styled(Container)`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const ResponsiveContent = styled(ResponsiveLayout)`
  ${({ theme }) => `
    height: calc(100% - 50px);
    padding: ${theme.padding};
    box-sizing: border-box;
    display: flex;
  `};
`;

const LeftContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-:right: 32px;
`;

const RightContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: 32px;
  width: 50%;
`;

// const YoutubeTitle = styled.p`
//   font-size: 18px;
//   color: ${({ theme }) => theme.labelColor ?? EColors.GREY};
//   padding-right: 8px;
//   transition: 0.3s;
// `;

// const YoutubeButton = styled.div`
//   padding: 2px 8px;
//   display: flex;
//   width: 150px;
//   align-items: center;
//   justify-content: center;
//   transition: 0.3s;
//   background-color: ${({ theme }) => EColors.BLACK + (theme.opacity ?? EOpacity.OPAC_70)};
//   box-shadow: 0px 0px 2px 5px ${({ theme }) => EColors.BLACK + (theme.opacity ?? EOpacity.OPAC_70)};
//   border-radius: 8px;
//   cursor: pointer;
//   text-decoration: none;
// `;
