import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Transition } from 'react-transition-group';
import { EColors, EOpacity, EFonts } from '../../config/styles';
import { useHover } from '../../hooks';
import { detailItemTransitionTheme } from '../../config/themes';
import { Item } from '../../config/contentData';
import { EConsts } from '../../config/staticData';
import { EAnimationState } from '../../types/enums';
import { GalleryContext } from '../../providers/GalleryProvider';
import { useGallery } from '../../hooks/useGallery';

interface Props {
  id: number;
  item: Item;
  showSeparator: boolean;
}

const DetailListItem = ({ item, showSeparator, id }: Props) => {
  const { isActive, mouseHover } = useHover();
  const { getGalleryById } = useGallery();
  const { prepareGallery } = useContext(GalleryContext);

  const handleOnItemClick = () => {
    const gallery = getGalleryById(id);

    if (gallery) {
      prepareGallery(gallery);
    }
  };

  return (
    <DetailsContainer
      showSeparator={showSeparator}
      onMouseEnter={mouseHover(true)}
      onMouseLeave={mouseHover(false)}
      onClick={handleOnItemClick}
    >
      {/* TODO nice to have some icon*/}
      {/* <Image src={item.icon}/> */}
      <DetailLabel>{item.label}</DetailLabel>
      <ButtonContainer>
        <Transition in={isActive} timeout={EConsts.TIMEOUT}>
          {(state) => {
            const isEntered = state === EAnimationState.ENTERED;
            return (
              <ThemeProvider theme={isEntered ? detailItemTransitionTheme : {}}>
                <IconContainer>
                  <Arrow style={{ fontSize: 28 }} />
                </IconContainer>
              </ThemeProvider>
            );
          }}
        </Transition>
      </ButtonContainer>
    </DetailsContainer>
  );
};

export default DetailListItem;

const DetailsContainer = styled.div<any>`
  display: flex;
  height: 30px;
  padding: 12px 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ showSeparator, theme }) =>
    `${showSeparator ? 0 : 1}px solid ${theme.borderColor}`};
  cursor: pointer;
  overflow-x: hidden;
`;

const DetailLabel = styled.p`
  color: ${({ theme }) => theme.labelColor};
  font-size: ${({ theme }) => theme.fontSize}px;
  font-family: ${EFonts.default};
  user-select: none;
`;

const ButtonContainer = styled.div`
  width: 70px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

const IconContainer = styled.div`
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  opacity: ${({ theme }) => theme.opacity ?? 0};
  transform: ${({ theme }) => theme.transformX ?? 'translateX(100%)'};
  transition: 0.3s;
`;

const Arrow = styled(ArrowForwardIosRoundedIcon)`
  color: ${({ theme }) => theme.labelColor};
`;
