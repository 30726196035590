import { ESize, EStatus } from '../types/enums';
import { EColors, EScreenBreakpoints, EOpacity } from './styles';

export const responsiveLayoutTheme = {
  [EScreenBreakpoints.DESKTOP]: {
    width: 1100,
  },
  [EScreenBreakpoints.TABLET_V]: {
    width: 768,
  },
  [EScreenBreakpoints.TABLET_H]: {
    width: 480,
  },
  [EScreenBreakpoints.MOBILE]: {
    // width: window.innerWidth,
    width: 320,
  },
};

export const mainSegmentTheme = {
  mobile: {
    direction: 'column',
    padding: '30px 16px',
  },
  desktop: {
    direction: 'row',
    padding: '70px 0',
  },
};

export const companySegmentTheme = {
  mobile: {
    compWidth: '100%',
    paddingBottom: 40,
    direction: 'column',
    padding: '40px 8px',
  },
  desktop: {
    compWidth: '45%',
    paddingBottom: 0,
    direction: 'row',
  },
};

export const galleryItemTheme = {
  desktop: {
    [ESize.SMALL]: {
      width: '20%',
    },
    [ESize.MEDIUM]: {
      width: '26%',
    },
    [ESize.LARGE]: {
      width: '32%',
    },
  },
  mobile: {
    width: '100%',
  },
  tablet: {
    [ESize.MEDIUM]: {
      width: '34%',
    },
    [ESize.LARGE]: {
      width: '46%',
    },
  },
  contentTransition: {
    opacity: 1,
    scale: 1.1,
  },
  detailsTransition: {
    opacity: 1,
    transformX: 'translateX(0px)',
  },
};

export const detailItemTransitionTheme = {
  opacity: 1,
  transformX: 'translateX(0px)',
};

export const contactItemTransitionTheme = {
  color: EColors.WHITE,
};

export const youtubeButtonTransitionTheme = {
  labelColor: EColors.WHITE,
  opacity: EOpacity.OPAC_100,
};

export const youtubeContainerTranstitionTheme = {
  width: 420,
  height: 300,
};

export const dotThemes = {
  [EStatus.ACTIVE]: {
    color: EColors.WHITE + EOpacity.OPAC_70,
    shadow: `0 0 2px 2px ${EColors.WHITE + EOpacity.OPAC_70}`,
  },
  [EStatus.INACTIVE]: {
    color: EColors.BLACK + EOpacity.OPAC_50,
    shadow: `0 0 2px 2px ${EColors.BLACK + EOpacity.OPAC_50}`,
  },
};

export const detailBoxTheme = {
  mobile: {
    padding: '70px 0px',
    fontSize: 20,
    justify: 'center',
    align: 'flex-start',
    labelColor: EColors.TEXT_GREY,
    borderColor: EColors.BLACK + EOpacity.OPAC_70,
    box: {
      marginTop: 30,
      bg: EColors.WHITE,
      shadow: '0px 0px',
      padding: '0px 20px',
    },
  },
  desktop: {
    padding: '0px 40px',
    fontSize: 24,
    justify: 'flex-start',
    align: 'center',
    labelColor: EColors.GREY,
    borderColor: EColors.WHITE + EOpacity.OPAC_20,
    box: {
      marginTop: 0,
      bg: EColors.BLACK + EOpacity.OPAC_70,
      shadow: `0px 0px 10px 20px ${EColors.BLACK + EOpacity.OPAC_70}`,
      padding: 0,
    },
  },
};

export const workSegmentTheme = {
  mobile: {
    fontSize: 24,
  },
  desktop: {
    fontSize: 30,
  },
};

export const footerTheme = {
  mobile: {
    justify: 'column',
    item: {
      padding: '8px 16px',
    },
  },
  desktop: {
    justify: 'row',
    item: {
      padding: '4px 16px',
    },
  },
};

export const galleryImageTheme = {
  full: {
    width: '100%',
    height: '100%',
  },
  regular: {
    width: '100%',
    height: '70vh',
  },
};

export const mobileMenuTransition = {
  height: '100vh',
  opacity: 1,
};
