import React, { createContext, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import GalleryModal from '../components/GalleryModal';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Gallery } from '../types/generalTypes';

export const GalleryContext = createContext({
  prepareGallery: (items: Gallery) => {},
});

export const GalleryModalProvider = ({ children }: any) => {
  const [showGallery, setShowGallery] = useState(false);
  const [galleryItems, setGalleryItems] = useState<Gallery>();

  const prepareGallery = (item: Gallery) => {
    setShowGallery(true);
    setGalleryItems(item);
  };

  const hanldeCloseModal = () => setShowGallery(false);

  return (
    <GalleryContext.Provider value={{ prepareGallery }}>
      {children}
      {/* <Modal open={showGallery} onClose={hanldeCloseModal}> */}
      <GalleryModal data={galleryItems} onClose={hanldeCloseModal} open={showGallery} />
      {/* </Modal> */}
    </GalleryContext.Provider>
  );
};
