import React, { createContext, useReducer, useCallback } from 'react';
import { prepareImagesState, prepareRequestImages, uniqueID } from '../utils/helperUtils';
import { api, EAPIRoutes } from '../Api';
import { Image, ImageData, Video } from '../types/generalTypes';
import { mutate } from 'swr';
import { VideoSection } from '../screens/admin/components/VideoSection';

enum EMGalleryActions {
  SET_IMAGES = 'SET_IMAGES',
  UPDATE_IMAGE = 'UPDATE_IMAGE',
  REMOVE_IMAGE = 'REMOVE_IMAGE',
  UPDATE_GALLERY = 'UPDATE_GALLERY',
  CLEAR_GALLERY = 'CLEAR_GALLERY',
  SET_GALLERY_LIST = 'SET_GALLERY_LIST',
  SET_INITIAL_GALLERY = 'SET_INITIAL_GALLERY',
  SET_VIDEO = 'SET_VIDEO',
  REMOVE_VIDEO = 'REMOVE_VIDEO',
}

interface MGalleryState {
  images: Image[];
  videos: Video[];
  title: string;
  description: string;
  _id?: number | null;
}

const initialState: MGalleryState = {
  images: [],
  videos: [],
  title: '',
  description: '',
  _id: null,
};

export const GalleryManagamentContext = createContext({
  gallery: initialState,
  setVideo: (video: Partial<Video>) => {},
  setImages: (files: File[]) => {},
  removeImage: (imageID: string) => {},
  updateImage: (imageData: ImageData, imageID: string) => {},
  updateGallery: (galleryData: any) => {},
  createGallery: (galleryData: any) => {},
  updateTitle: (e: React.ChangeEvent) => {},
  setInitialGallery: (gallery: any) => {},
  clearGallery: () => {},
  removeGallery: (cb: () => void) => {},
  updateDescription: (e: React.ChangeEvent) => {},
  removeVideo: (videoID: string) => {},
});

const reducer = (state: MGalleryState, action: any) => {
  switch (action.type) {
    case EMGalleryActions.UPDATE_GALLERY:
      return { ...state, ...action.gallery };
    case EMGalleryActions.SET_IMAGES:
      return { ...state, images: [...state.images, ...action.images] };

    case EMGalleryActions.UPDATE_IMAGE: {
      const images = state.images.map((item) => {
        const newImage = { ...item, loading: false, ...action.image };
        return item._id === action.imageID ? newImage : item;
      });
      return { ...state, images };
    }

    case EMGalleryActions.REMOVE_IMAGE: {
      const images = state.images.filter(({ _id }) => _id !== action.imageID);
      return { ...state, images };
    }
    case EMGalleryActions.SET_VIDEO:
      return { ...state, videos: [...state.videos, action.video] };
    case EMGalleryActions.REMOVE_VIDEO:
      return { ...state, videos: state.videos.filter((v) => v._id !== action.videoID) };
    case EMGalleryActions.SET_INITIAL_GALLERY:
      return { ...state, ...action.gallery };
    case EMGalleryActions.CLEAR_GALLERY:
      return initialState;
    // case EMGalleryActions.SET
    default:
      return state;
  }
};

export const GalleryManagamentProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setInitialGallery = (gallery: any) => {
    dispatch({
      type: EMGalleryActions.SET_INITIAL_GALLERY,
      gallery,
    });
  };

  const setImages = useCallback((files: File[]) => {
    const images = prepareImagesState(files);
    dispatch({
      type: EMGalleryActions.SET_IMAGES,
      images,
    });

    files.map(uploadImage);
  }, []);

  const setVideo = useCallback(async (video: Partial<Video>) => {
    dispatch({
      type: EMGalleryActions.SET_VIDEO,
      video,
    });
  }, []);

  const removeImage = useCallback(async (imageID: string | number, withoutAPI = false) => {
    if (!withoutAPI) {
      try {
        await api.removeImage(imageID);
      } catch (e) {
        console.log(e);
      }
    }

    dispatch({
      type: EMGalleryActions.REMOVE_IMAGE,
      imageID,
    });
  }, []);

  const updateImage = useCallback((image: ImageData, imageID: string | number) => {
    dispatch({
      type: EMGalleryActions.UPDATE_IMAGE,
      image,
      imageID,
    });
  }, []);

  const updateGallery = useCallback(async () => {
    const { images, ...rest } = state;
    try {
      await api.updateGallery({ ...rest, images: prepareRequestImages(images) });
      mutate(EAPIRoutes.GET_ADMIN_GALLERY);
    } catch (e) {
      console.log(e);
    }
  }, [state]);

  const updateTitle = useCallback(({ target }: any) => {
    dispatch({
      type: EMGalleryActions.UPDATE_GALLERY,
      gallery: { title: target.value },
    });
  }, []);

  const removeGallery = useCallback(
    async (callback) => {
      try {
        await api.removeGallery(state._id);
        callback();
        clearGallery();
        mutate(EAPIRoutes.GET_ADMIN_GALLERY);
      } catch (e) {
        console.log(e);
      }
    },
    [state],
  );

  const removeVideo = useCallback((videoID) => {
    dispatch({
      type: EMGalleryActions.REMOVE_VIDEO,
      videoID,
    });
  }, []);

  const createGallery = async () => {
    const { images, ...rest } = state;
    try {
      await api.addGallery({ ...rest, images: prepareRequestImages(images) });
      clearGallery();
      mutate(EAPIRoutes.GET_ADMIN_GALLERY);
    } catch (e) {
      console.log(e);
    }
  };

  const clearGallery = () => {
    dispatch({
      type: EMGalleryActions.CLEAR_GALLERY,
    });
  };

  const uploadImage = async (file: File, index: number) => {
    const formData = new FormData();
    formData.append('image', file);
    // const buffId = uniqueID();
    try {
      const { data }: any = await api.uploadImage(formData);
      updateImage(data as ImageData, index);
    } catch (e) {
      removeImage(index, true);
      console.log(e);
    }
  };

  const updateDescription = ({ target }: any) => {
    dispatch({
      type: EMGalleryActions.UPDATE_GALLERY,
      gallery: { description: target.value },
    });
  };

  return (
    <GalleryManagamentContext.Provider
      value={{
        gallery: state,
        setVideo,
        setImages,
        removeImage,
        updateImage,
        updateGallery,
        createGallery,
        updateTitle,
        setInitialGallery,
        clearGallery,
        removeGallery,
        updateDescription,
        removeVideo,
      }}
    >
      {children}
    </GalleryManagamentContext.Provider>
  );
};
