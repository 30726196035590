import React, { useState, useCallback, useEffect } from 'react';
import { Tab } from '../types/generalTypes';
import { useHistory } from 'react-router-dom';

type TabTuple = [number, (v: number) => void];

export const useTabs = (tabs: Tab[] | null = null): TabTuple => {
  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory();

  useEffect(() => {
    pushPath(activeTab);
  }, []);

  const pushPath = useCallback((currentTab) => {
    if (tabs) {
      history.push(tabs[currentTab].path!);
    }
  }, []);

  const tabChangeHandler = useCallback((v) => {
    setActiveTab(v);
    pushPath(v);
  }, []);

  return [activeTab, tabChangeHandler];
};
