import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import SwapHorizRoundedIcon from '@material-ui/icons/SwapHorizRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import IconButton from '@material-ui/core/IconButton';
import { EColors, EFonts, EOpacity } from '../../config/styles';
import { useGallery } from '../../hooks/useGallery';
import { strings } from '../../config/strings';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ResponsiveLayoutContext } from '../../providers/ResponsiveLayoutProvider';

enum EImageType {
  AFTER = 1,
  BEFORE,
}

export const AboutObjectSegment = () => {
  const [toggleState, setToggleState] = useState(false);
  const { gallery } = useGallery();
  const { isTablet } = useContext(ResponsiveLayoutContext);
  const type = toggleState ? EImageType.AFTER : EImageType.BEFORE;

  const handleSwitchClick = useCallback(() => setToggleState((prev) => !prev), []);

  const cards = useMemo(() => {
    return gallery;
  }, [gallery]);

  const renderCards = useCallback((card, index) => {
    return (
      <Slide index={index}>
        <Card>
          <ImageContainer>
            <Image src={card.images[0].original.url || ''} />
            <ImageFooter>
              <ImageStatus>
                {toggleState ? strings.aboutObject.after : strings.aboutObject.before}
              </ImageStatus>
              <Button onClick={handleSwitchClick}>
                <Switch fontSize={'inherit'} />
              </Button>
            </ImageFooter>
          </ImageContainer>
          <Description>
            <Title>{card.title}</Title>
            <Separator></Separator>
            <Label>Atlikti darbai:</Label>
            <Content>{card.description}</Content>
          </Description>
        </Card>
      </Slide>
    );
  }, []);

  return (
    <Container>
      <CarouselProvider
        naturalSlideHeight={320}
        naturalSlideWidth={300}
        totalSlides={cards?.length || 0}
        visibleSlides={isTablet ? 1 : 2}
        isIntrinsicHeight
        infinite={true}
      >
        <Slider>{cards?.map(renderCards)}</Slider>

        <Back>
          <ArrowBackIosRoundedIcon />
        </Back>
        <Next>
          <ArrowForwardIosRoundedIcon />
        </Next>
      </CarouselProvider>
    </Container>
  );
};

const buttonMixin = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  outline: none;
  &:hover {
    opacity: 1;
  }
`;

const Back = styled(ButtonBack)`
  ${buttonMixin};
  left: -10px;
`;

const Next = styled(ButtonNext)`
  ${buttonMixin};
  right: -10px;
`;

const Container = styled.div`
  width: calc(100% - 40px);
  margin: auto;
  margin-top: 70px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const Card = styled.div`
  height: 320px;
  border: 1px solid ${EColors.BLACK + EOpacity.OPAC_10};
  box-shadow: 5px 5px 15px 5px ${EColors.BLACK + EOpacity.OPAC_10};
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin: 20px;
`;

const Description = styled.div`
  width: 40%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-size: 24px;
  color: ${EColors.TEXT_GREY};
  font-family: ${EFonts.default};
  text-align: left;
  font-weight: 600;
`;

const Content = styled.p`
  font-size: 16px;
  color: ${EColors.TEXT_GREY};
  font-family: ${EFonts.default};
  padding-top: 8px;
  text-align: left;
`;

const Label = styled.p`
  font-size: 20px;
  font-family: ${EFonts.default};
  color: ${EColors.TEXT_GREY};
`;

const Separator = styled.div`
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: ${EColors.BLACK + EOpacity.OPAC_10};
`;

const ImageContainer = styled.div`
  width: 60%;
  height: 100%;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const ImageFooter = styled.div`
  width: 100%;
  height: 40px;
  padding: 8px 0;
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  background-color: ${EColors.BLACK + EOpacity.OPAC_50};
`;

const ImageStatus = styled.p`
  font-size: 20px;
  font-family: ${EFonts.default};
  color: ${EColors.WHITE};
`;

const Button = styled(IconButton)``;

const Switch = styled(SwapHorizRoundedIcon)`
  color: ${EColors.WHITE};
`;
