import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Tabs } from '@material-ui/core';
import type { Tab as TabData } from '../../types/generalTypes';
import { TABS } from '../../config/staticData';
import { useTabs } from '../../hooks';
import Header from '../components/Header';
import ResponsiveLayout from '../../components/ResponsiveLayout';
import AdminRoutes from '../../routes/AdminRoutes';
import { AdminContext } from '../../providers/AdminProvider';
import { MenuItem } from '../../components/styled';
import { EColors } from '../../config/styles';

const AdminScreen = () => {
  const [activeTab, setActiveTab] = useTabs(TABS);
  const { logout } = useContext(AdminContext);

  const handleTabChange = useCallback((e: React.ChangeEvent<any>, v: number) => {
    if (v === TABS.length - 1) {
      // becouse last tab has logout action
      logout();
    } else {
      setActiveTab(v);
    }
  }, []);

  const renderTabs = ({ label }: TabData) => {
    return <MenuItem label={label} key={label} />;
  };

  return (
    <Container>
      <Header />
      <ResponsiveLayout>
        <Wrapper>
          <MenuContainer>
            <Tabs value={activeTab} onChange={handleTabChange} indicatorColor={'primary'}>
              {TABS.map(renderTabs)}
            </Tabs>
          </MenuContainer>
          <ContentWrapper>
            <AdminRoutes />
          </ContentWrapper>
        </Wrapper>
      </ResponsiveLayout>
    </Container>
  );
};

export default AdminScreen;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding-bottom: 16px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 60px - 50px);
  margin-top: 30px;
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const MenuContainer = styled.div`
  border-bottom: 1px solid ${EColors.GREY};
`;
