import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';
import { images } from '../assets/images';

export interface Contact {
  label: string | null;
  icon: [IconPrefix, IconName];
  link: string;
}

export interface Item {
  label: string;
  icon: string;
}

export const CONTACTS: Contact[] = [
  {
    label: '+37062690870',
    icon: ['fas', 'phone-alt'],
    link: 'tel:+37062690870',
  },
  {
    label: 'o.vaisvila90@gmail.com',
    icon: ['fas', 'envelope'],
    link: 'mailto: o.vaisvila90@gmail.com',
  },
  {
    label: null,
    icon: ['fab', 'facebook'],
    link: 'https://www.facebook.com/ovidijus.vai',
  },
  {
    label: null,
    icon: ['fab', 'youtube'],
    link: 'http://youtube.com',
  },
];

export const WORKS: Item[] = [
  {
    label: 'Gipso sukimas',
    icon: images.works.screwdriver,
  },
  {
    label: 'Glaistymas',
    icon: images.works.puttyKnife,
  },
  {
    label: 'Dažymas',
    icon: images.works.paintRoller,
  },
  {
    label: 'Plytelių klijavimas (45°)',
    icon: images.works.deg,
  },
  {
    label: 'Santechnika',
    icon: images.works.faucet,
  },
  {
    label: 'Elektros instaliacija',
    icon: images.works.electricity,
  },
  {
    label: 'Laminato dėjimas',
    icon: images.works.hammer,
  },
];
