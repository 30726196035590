export enum EMainRoutes {
  MAIN = '/',
  ADMIN = '/admin',
  LOGIN = '/login',
}

export enum EAdminRoutes {
  GALLERY = '/admin/gallery',
  SETTINGS = '/admin/settings',
}

export enum ESize {
  SMALL,
  MEDIUM,
  LARGE,
}

export enum EAnimationState {
  UNMOUNTED = 'unmounted',
  EXITED = 'exited',
  ENTERING = 'entering',
  ENTERED = 'entered',
  EXITING = 'exiting',
}

export enum EContacts {
  PHONE,
  MAIL,
  FACEBOOK,
}

export enum EStatus {
  ACTIVE,
  INACTIVE,
}

export enum EChangePassKeys {
  OLD_PASSWORD = 'oldPassword',
  NEW_PASSWORD = 'newPassword',
  RE_NEW_PASSWORD = 'reNewPassword',
}

export enum ELoginKeys {
  username = 'username',
  password = 'password',
}

export enum EDirections {
  BACKWARD = -1,
  FORWARD = 1,
}

export enum EImageFilterType {
  AFTER = 1,
  BEFORE,
}
