export const strings = {
  companySegment: {
    title: 'Apdailos meistrai',
    content: 'Konsultavimas, sąmatų parengimas, sąskaitų išrašymas',
    logoTitle: 'Atsinaujink būstą',
  },
  landingDetails: [
    'Gipso sukimas',
    'Glaistymas',
    'Dažymas',
    'Plytelių klijavimas (45°)',
    'Santechnika',
    'Elektros instaliacija',
    'Laminato dėjimas',
  ],
  logoTitle: 'Apdailos meistrai',
  copyright: '© 2020. Visos teisės saugomos.',
  gallery: {
    addNewLabel: 'Nauja galerija',
    title: 'Galerijos pavadinimas',
    imageCategoryLabel: 'Category',
    createButtonLabel: 'Pridėti',
    removeButtonLabel: 'Ištrinti',
    updateButtonLabel: 'Išsaugoti',
    upload: 'Atitemkite ir paleiskite arba paspauskite ir įkelkite nuotraukas',
    descripton: 'Aprašymas',
  },
  aboutObject: {
    before: 'Prieš',
    after: 'Po',
    urlField: 'Įrašo nuoroda',
    photos: 'Nuotraukos',
    videos: 'Įrašai',
  },
  login: {
    username: 'Prisijungimo vardas',
    password: 'Slaptažodis',
    button: 'Prisijungti',
  },
  adminMenu: {
    gallery: 'Galerija',
    settings: 'Nustatymai',
    logout: 'Atsijungti',
  },
  settings: {
    changePass: {
      title: 'Slaptažodžio keitimas',
      oldPassword: 'Senas slaptažodis',
      newPassword: 'Naujas slaptažodis',
      reNewPassword: 'Pakartoti naują slaptažodį',
      changeButton: 'Keisti',
    },
    newUser: {
      title: 'Naujas vartotojas',
      username: 'Vartotojo vardas',
      password: 'Slaptažodis',
      rePassword: 'Pakartoti slaptažodį',
    },
  },
  youtube: 'Žiūrėk mus',
};
