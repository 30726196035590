import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import { ELoginKeys } from '../types/enums';
import { strings } from '../config/strings';
import { EColors } from '../config/styles';
import { AdminContext } from '../providers/AdminProvider';
import { FORM_CONFIG } from '../config/staticData';

interface LoginData {
  username: string;
  password: string;
}

const LoginScreen = () => {
  const [formData, setFormData] = useState<Partial<LoginData>>({});
  const { loginUser } = useContext(AdminContext);

  const inputHandler = useCallback(({ target }) => {
    setFormData((prev) => ({ ...prev, [target.name]: target.value }));
  }, []);

  const clickHandler = () => loginUser(formData);

  return (
    <Container>
      <LoginContainer>
        <TextField
          label={strings.login[ELoginKeys.username]}
          value={formData.username}
          name={ELoginKeys.username}
          onChange={inputHandler}
          {...FORM_CONFIG}
        />
        <TextField
          label={strings.login[ELoginKeys.password]}
          value={formData.password}
          type={'password'}
          name={ELoginKeys.password}
          onChange={inputHandler}
          {...FORM_CONFIG}
        />
        <ButtonWrapper>
          <Button size={'medium'} variant="outlined" color="primary" onClick={clickHandler}>
            {strings.login.button}
          </Button>
        </ButtonWrapper>
      </LoginContainer>
    </Container>
  );
};

export default LoginScreen;

const Container = styled.div`
  width: 100;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid ${EColors.GREY};
  border-radius: 10px;
`;

const ButtonWrapper = styled.div`
  padding: 10px;
`;
