import React, { useContext, useCallback, useState } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';
import { Tabs, TextField, Button, ButtonGroup, ButtonProps, Divider } from '@material-ui/core';
import {
  GalleryManagamentContext,
  GalleryManagamentProvider,
} from '../../../providers/GalleryManagamentProvider';
import { useTabs } from '../../../hooks';
import { withProvider } from '../../../hoc/withProvider';
import { strings } from '../../../config/strings';
import { EColors } from '../../../config/styles';
import { EAPIRoutes, api } from '../../../Api';
import { MenuItem } from '../../../components/styled';
import { PhotosSection } from '../components/PhotosSection';
import { VideoSection } from '../components/VideoSection';

enum EContentTypes {
  PHOTOS = 1,
  VIDEO,
}

const CONTENT_TYPE_BUTTONS = [
  { type: EContentTypes.PHOTOS, label: strings.aboutObject.photos },
  { type: EContentTypes.VIDEO, label: strings.aboutObject.videos },
];

const GallerySegment = () => {
  const [activeTab, setActiveTab] = useTabs();
  const [contentType, setContentType] = useState(EContentTypes.PHOTOS);
  const { data: resp }: any = useSWR(EAPIRoutes.GET_ADMIN_GALLERY, api.getAdminGalleryList);

  const {
    gallery,
    createGallery,
    updateTitle,
    updateGallery,
    setInitialGallery,
    clearGallery,
    removeGallery,
    updateDescription,
  } = useContext(GalleryManagamentContext);

  const handleActiveTab = (e: React.ChangeEvent<any>, i: number) => {
    if (i) {
      setInitialGallery(resp.data[i - 1]);
    } else {
      clearGallery();
    }
    setActiveTab(i);
  };

  const handleRemoveGallery = useCallback(() => {
    removeGallery(setTabToInitialValue);
  }, [gallery]);

  const handleContentTypeSwitch = useCallback(
    (type: EContentTypes) => () => setContentType(type),
    [],
  );

  const setTabToInitialValue = useCallback(() => setActiveTab(0), []);

  const renderGalleryTabs = useCallback(
    ({ title, _id }: any) => <MenuItem label={title} key={_id} />,
    [],
  );

  const renderContentByType = useCallback(() => {
    switch (contentType) {
      case EContentTypes.PHOTOS:
        return <PhotosSection />;
      case EContentTypes.VIDEO:
        return <VideoSection />;
      default:
        return null;
    }
  }, [contentType]);

  const renderContentTypeButtons = useCallback(
    ({ type, label }) => {
      const props: ButtonProps = type === contentType ? { variant: 'contained' } : {};
      return (
        <Button onClick={handleContentTypeSwitch(type)} {...props}>
          {label}
        </Button>
      );
    },
    [contentType],
  );

  return (
    <Container>
      <ListContainer>
        <Tabs
          value={activeTab}
          onChange={handleActiveTab}
          indicatorColor={'primary'}
          orientation={'vertical'}
        >
          <MenuItem label={strings.gallery.addNewLabel} />
          {resp?.data.map(renderGalleryTabs)}
        </Tabs>
      </ListContainer>
      <ContentContainer>
        <MainInfo>
          <Wrapper>
            <TextField
              fullWidth={true}
              label={strings.gallery.title}
              onChange={updateTitle}
              value={gallery.title}
              variant="outlined"
            />
            <ButtonContainer>
              {!gallery._id ? (
                <Button variant="contained" color="primary" size={'large'} onClick={createGallery}>
                  {strings.gallery.createButtonLabel}
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="default"
                    size={'large'}
                    onClick={updateGallery}
                  >
                    {strings.gallery.updateButtonLabel}
                  </Button>
                  <Separator />
                  <Button
                    variant="contained"
                    color="secondary"
                    size={'large'}
                    onClick={handleRemoveGallery}
                  >
                    {strings.gallery.removeButtonLabel}
                  </Button>
                </>
              )}
            </ButtonContainer>
          </Wrapper>
          <TextField
            fullWidth={true}
            label={strings.gallery.descripton}
            multiline
            margin={'normal'}
            rows={5}
            onChange={updateDescription}
            value={gallery.description}
            variant="outlined"
          />
        </MainInfo>
        <Divider />
        <ButtonsContainer>
          <ButtonGroup>{CONTENT_TYPE_BUTTONS.map(renderContentTypeButtons)}</ButtonGroup>
        </ButtonsContainer>
        {renderContentByType()}
      </ContentContainer>
    </Container>
  );
};

export default withProvider(GallerySegment, [GalleryManagamentProvider]);

const Container = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
`;

const ContentContainer = styled.div`
  width: calc(100% - 200px);
  height: 100%;
  padding: 20px 20px 0 20px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const MainInfo = styled.div`
  padding-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const Separator = styled.div`
  width: 20px;
`;

const ListContainer = styled.div`
  width: 200px;
  border-right: 1px solid ${EColors.GREY};
`;

const ButtonsContainer = styled.div`
  padding-top: 20px;
`;
