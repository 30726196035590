import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ResponsiveLayoutContext } from '../../providers/ResponsiveLayoutProvider';
import { WORKS, Item } from '../../config/contentData';
import { detailBoxTheme as theme } from '../../config/themes';
import DetailListItem from '../components/DetailListItem';

const WorkDetailsBox = () => {
  const { isTablet } = useContext(ResponsiveLayoutContext);
  const renderDetails = (item: Item, index: number) => {
    const isLast = index === WORKS.length - 1;
    return <DetailListItem item={item} id={index} key={item.label!} showSeparator={isLast} />;
  };

  return (
    <ThemeProvider theme={isTablet ? theme.mobile : theme.desktop}>
      <DetailsBox>{WORKS.map(renderDetails)}</DetailsBox>
    </ThemeProvider>
  );
};

export default WorkDetailsBox;

const DetailsBox = styled.div`
  ${({ theme: { box } }) => `
      margin-top: ${box.marginTop}px;
      max-width: 100%;
      background-color: ${box.bg};
      box-shadow: ${box.shadow};
      border-radius: 4px;
      padding: ${box.padding};
  `}
`;
