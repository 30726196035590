import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { images } from '../../assets/images';

let settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplaySpeed: 6000,
  centerMode: true,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  centerPadding: '0px',
};

const HomeImageCarousel = () => {
  return (
    <Slider {...settings}>
      {images.landing.map((image) => (
        <LandingImage key={image} src={image} />
      ))}
    </Slider>
  );
};

export default HomeImageCarousel;

const LandingImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;
