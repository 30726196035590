import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ResponsiveLayoutContext } from '../providers/ResponsiveLayoutProvider';
import { responsiveLayoutTheme } from '../config/themes';
import { EStyles } from '../config/styles';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const ResponsiveLayout = ({ children, className }: IProps) => {
  const { breakpoint }: any = useContext(ResponsiveLayoutContext);

  return (
    <ThemeProvider theme={responsiveLayoutTheme[breakpoint as never]}>
      <Container className={className}>{children}</Container>
    </ThemeProvider>
  );
};

export default ResponsiveLayout;

const Container = styled.div`
  margin: auto;
  min-width: ${({ theme }) => theme.width}px;
  max-width: ${EStyles.APP_WIDTH}px;
`;
