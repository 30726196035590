import React, { useCallback, useContext, useMemo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Slider from 'react-slick';
import { Transition } from 'react-transition-group';
import { ResponsiveLayoutContext } from '../../providers/ResponsiveLayoutProvider';
import { useHover } from '../../hooks';
import { EConsts } from '../../config/staticData';
import { ESize, EAnimationState } from '../../types/enums';
import { galleryItemTheme as theme } from '../../config/themes';
import { EColors, EOpacity, EFonts } from '../../config/styles';
import { Gallery } from '../../types/generalTypes';

interface IProps {
  size: ESize;
  item: Gallery;
  onClick: (item: Gallery) => void;
  index: number;
}

let settings = {
  autoplay: true,
  arrows: false,
  centerPadding: '0px',
};

const GalleryItem = ({ size, item, onClick, index }: IProps) => {
  const { isActive, mouseHover } = useHover();
  const { isMobile, isTablet } = useContext(ResponsiveLayoutContext);
  const handleClick = useCallback(() => onClick(item), [item, onClick]);

  const sliderImages = useMemo(() => item.images.slice(0, 3), [item]);

  const renderAnimatedContent = useCallback((state: EAnimationState) => {
    const isEntered = state === EAnimationState.ENTERED;
    return (
      <ThemeProvider theme={isEntered ? theme.contentTransition : {}}>
        <SliderCard {...settings} autoplaySpeed={5000 + (size + 1) * index * 500}>
          {sliderImages.map(({ original, _id }) => (
            <GalleryImage key={_id} src={original.url} />
          ))}
        </SliderCard>
        <BackDrop state={state} />
        <DetailsContainer>
          <Transition in={isEntered} timeout={EConsts.TIMEOUT}>
            {(s) => (
              <ThemeProvider theme={s === EAnimationState.ENTERED ? theme.detailsTransition : {}}>
                <Title>{item.title}</Title>
              </ThemeProvider>
            )}
          </Transition>
        </DetailsContainer>
      </ThemeProvider>
    );
  }, []);

  let themeByDevice = theme.desktop[size];
  if (isTablet) {
    themeByDevice = theme.tablet[size === ESize.SMALL ? ESize.LARGE : size];
  }

  if (isMobile) {
    themeByDevice = theme.mobile;
  }

  return (
    <ThemeProvider theme={themeByDevice}>
      <Container size={size} onMouseEnter={mouseHover(true)} onMouseLeave={mouseHover(false)}>
        <Button onClick={handleClick}>
          <Transition in={isActive} timeout={EConsts.TIMEOUT}>
            {renderAnimatedContent}
          </Transition>
        </Button>
      </Container>
    </ThemeProvider>
  );
};

export default GalleryItem;

const Container = styled.div<any>`
  width: ${({ theme }) => theme.width};
  height: 270px;
  padding: 8px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
`;

const Button = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;

const GalleryImage = styled.img`
  max-width: 100%;
  height: 270px;
`;

const BackDrop = styled.div<any>`
  width: 100%;
  height: 100%;
  opacity: ${({ theme }) => theme.opacity ?? 0};
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${EColors.BLACK + EOpacity.OPAC_40};
  transition: 0.3s;
`;

const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h5`
  font-size: 22px;
  color: ${EColors.WHITE};
  opacity: ${({ theme }) => theme.opacity ?? 0};
  transition: 0.3s;
  font-family: ${EFonts.default};
  transform: ${({ theme }) => theme.transformX ?? 'translateX(-100%)'};
`;

const SliderCard = styled(Slider)`
  height: 270px;
  border-radius: 8px;
  transform: scale(${({ theme }) => theme.scale ?? 1});
  transition: 0.3s;
`;
