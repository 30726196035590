import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { EMainRoutes } from '../types/enums';
import { AdminProvider } from '../providers/AdminProvider';

import HomeScreen from '../screens/HomeScreen';
import LoginScreen from '../screens/LoginScreen';
import AdminScreen from '../screens/admin/AdminScreen';

import ProtectedRoute from '../components/ProtectedRoute';

const AppRoutes = () => {
  return (
    <Router>
      <AdminProvider>
        <Switch>
          <Route exact path={EMainRoutes.MAIN} component={HomeScreen} />
          <ProtectedRoute path={EMainRoutes.ADMIN} component={AdminScreen} />
          <Route path={EMainRoutes.LOGIN} component={LoginScreen} />
        </Switch>
      </AdminProvider>
    </Router>
  );
};

export default AppRoutes;
