import React, { useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EAnimationState } from '../../types/enums';
import { useHover } from '../../hooks';
import { contactItemTransitionTheme } from '../../config/themes';
import { EConsts } from '../../config/staticData';
import { EColors, EOpacity, EFonts } from '../../config/styles';

interface IProps {
  data: any;
  isLast: boolean;
  onClick?: (item: any) => void;
  showLabel: boolean;
  flat?: boolean;
}

const ContactItem = ({ data, isLast, onClick, showLabel, flat = false }: IProps) => {
  const { isActive, mouseHover } = useHover();

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick(data);
    }
  }, [onClick, data]);

  const renderAnimatedContent = useCallback(
    (state: EAnimationState) => {
      const isEnterd = state === EAnimationState.ENTERED;
      return (
        <ThemeProvider theme={isEnterd ? contactItemTransitionTheme : {}}>
          <Icon icon={data.icon} size={flat ? 'lg' : '1x'} />
          {showLabel && data.label && <Label flat={flat}>{data.label}</Label>}
        </ThemeProvider>
      );
    },
    [showLabel, flat],
  );

  return (
    <ItemContainer
      isLast={isLast}
      onMouseEnter={mouseHover(true)}
      onMouseLeave={mouseHover(false)}
      href={data.link}
      onClick={handleClick}
      target={'_blank'}
      flat={flat}
      // replace={true}
    >
      <Transition in={isActive} timeout={EConsts.TIMEOUT}>
        {renderAnimatedContent}
      </Transition>
    </ItemContainer>
  );
};

export default ContactItem;

const ItemContainer = styled.a<any>`
  display: flex;
  flex-direction: row;
  margin: ${({ flat }) => (flat ? 16 : 0)}px 0;
  padding: ${({ theme }) => theme.item?.padding || '4px 16px'};
  align-items: center;
  border-right: ${({ isLast }) => (isLast ? 0 : 1)}px solid ${EColors.WHITE + EOpacity.OPAC_20};
  cursor: pointer;
  text-decoration: none;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.color ?? EColors.GREY};
`;

const Label = styled.p<any>`
  font-size: ${({ flat }: any) => (flat ? 20 : 16)}px;
  color: ${({ theme }) => theme.color ?? EColors.GREY};
  padding-left: ${({ flat }: any) => (flat ? 16 : 8)}px;
  font-family: ${EFonts.default};
`;
