import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  TextField,
  Button,
  Checkbox,
  FormLabel,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  withStyles,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Close';
import { strings } from '../../../config/strings';
import { EImageFilterType } from '../../../types/enums';
import { GalleryManagamentContext } from '../../../providers/GalleryManagamentProvider';
import { uniqueID } from '../../../utils/helperUtils';
import { Video } from '../../../types/generalTypes';
import { isEmpty } from 'lodash';

interface FilterBox {
  label: string;
  type: EImageFilterType;
}

const FILTERS: FilterBox[] = [
  {
    label: strings.aboutObject.before,
    type: EImageFilterType.BEFORE,
  },
  {
    label: strings.aboutObject.after,
    type: EImageFilterType.AFTER,
  },
];

const HEADER_CELLS = [
  strings.aboutObject.urlField,
  strings.aboutObject.before,
  strings.aboutObject.after,
  '',
];

export const VideoSection = () => {
  const [url, setUrl] = useState<string>();
  const [filterType, setFilterType] = useState<EImageFilterType>();
  const { gallery, setVideo, removeVideo } = useContext(GalleryManagamentContext);

  const toggleFilterTypeHandler = useCallback(
    (type: EImageFilterType) => () => {
      setFilterType(filterType !== type ? type : undefined);
    },
    [filterType],
  );

  const addVideoHandler = useCallback(() => {
    if (!isEmpty(url)) {
      setVideo({ _id: uniqueID(), url, filterType });
      setUrl('');
      setFilterType(undefined);
    }
  }, [url, filterType]);

  const removeVideoHandler = useCallback((videoID: string) => () => removeVideo(videoID), []);

  const urlInputHandler = useCallback(({ target }) => setUrl(target.value), []);

  const renderFilterItem = useCallback(
    (withLabel: boolean = false) => ({ label, type }: FilterBox) => {
      return (
        <Box>
          {withLabel && <FormLabel>{label}</FormLabel>}
          <StyledCheckBox onChange={toggleFilterTypeHandler(type)} checked={type === filterType} />
        </Box>
      );
    },
    [filterType],
  );

  const renderFilterValues = useCallback(
    (filterType) => ({ type }: FilterBox) => {
      const isChecked = filterType === type;
      return (
        <TableCell size={'small'} padding={'none'} align={'center'}>
          <Checkbox checked={isChecked} />
        </TableCell>
      );
    },
    [gallery],
  );

  const renderVideoItem = useCallback(({ _id, url, filterType }: Video) => {
    return (
      <TableRow key={_id}>
        <TableCell padding={'none'} size={'small'} style={{ width: '100%' }}>
          <Url>{url}</Url>
        </TableCell>
        {FILTERS.map(renderFilterValues(filterType))}
        <TableCell size={'small'}>
          <IconButton size={'small'}>
            <StyledRemoveIcon
              color={'error'}
              fontSize={'default'}
              onClick={removeVideoHandler(_id)}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }, []);

  const renderHeaderCell = useCallback((label: string) => {
    return <TableCell size={'small'}>{label}</TableCell>;
  }, []);

  return (
    <Container>
      <Wrapper>
        <TextField
          fullWidth={true}
          label={strings.aboutObject.urlField}
          onChange={urlInputHandler}
          value={url}
          variant={'outlined'}
        />
        <Boxes>{FILTERS.map(renderFilterItem(true))}</Boxes>
        <Button variant={'contained'} color={'primary'} size={'large'} onClick={addVideoHandler}>
          {strings.gallery.createButtonLabel}
        </Button>
      </Wrapper>
      {!isEmpty(gallery.videos) && (
        <Table>
          <TableHead>
            <TableRow>{HEADER_CELLS.map(renderHeaderCell)}</TableRow>
          </TableHead>
          <TableBody>{gallery.videos.map(renderVideoItem)}</TableBody>
        </Table>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
`;

const Boxes = styled.div`
  height: 100%;
  display: flex;
  padding: 0 20px;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
`;

const VideoRowContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
`;

const Url = styled.p`
  width: 100%;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  align-self: center;
  padding: 6px;
`;

const StyledCheckBox = withStyles({
  root: {
    paddingBottom: 0,
  },
})(Checkbox);
