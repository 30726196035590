import React, { createContext, useState, useEffect } from 'react';
import { findBreakpoint } from '../utils/helperUtils';
import { EScreenBreakpoints } from '../config/styles';

interface IProps {
  children: React.ReactNode;
}

export const ResponsiveLayoutContext = createContext({
  breakpoint: findBreakpoint(),
  isMobile: false,
  isTablet: false,
});

export const ResponsiveLayoutProvider = ({ children }: IProps) => {
  const [breakpoint, setBreakpoint] = useState(findBreakpoint());

  useEffect(() => {
    const handleResize = () => {
      const nextBreakpoint = findBreakpoint(breakpoint);
      if (breakpoint !== nextBreakpoint) {
        setBreakpoint(nextBreakpoint);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  const isMobile = breakpoint === EScreenBreakpoints.MOBILE;

  const isTablet = breakpoint === EScreenBreakpoints.TABLET_H || isMobile;

  return (
    <ResponsiveLayoutContext.Provider value={{ breakpoint, isMobile, isTablet }}>
      {children}
    </ResponsiveLayoutContext.Provider>
  );
};
