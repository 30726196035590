import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { LandingPage, CompanySegment, GallerySegment, AboutObjectSegment } from './segments';
import { EColors, EOpacity } from '../config/styles';
import { mainSegmentTheme, footerTheme } from '../config/themes';
import { strings } from '../config/strings';
import ResponsiveLayout from '../components/ResponsiveLayout';
import ContactItem from './components/ContactItem';
import WorkDetailsBox from './components/WorkDetailsBox';
import { CONTACTS, Contact } from '../config/contentData';
import { ResponsiveLayoutContext } from '../providers/ResponsiveLayoutProvider';

const HomeScreen = () => {
  const { isTablet, isMobile } = useContext(ResponsiveLayoutContext);
  const renderItem = (item: Contact, index: number) => {
    const isLast = index === CONTACTS.length - 1;
    return <ContactItem key={index} showLabel={true} isLast={isLast} data={item} />;
  };

  return (
    <Container>
      <LandingPage />
      <ResponsiveLayout>
        <ThemeProvider theme={mainSegmentTheme.desktop}>
          {isTablet && <WorkDetailsBox />}
          {/* <AboutObjectSegment /> */}
          <CompanySegment />
          <GallerySegment />
        </ThemeProvider>
      </ResponsiveLayout>
      <Footer>
        <ThemeProvider theme={isMobile ? footerTheme.mobile : footerTheme.desktop}>
          <ContactsContainer>{CONTACTS.map(renderItem)}</ContactsContainer>
          <Copyright>{strings.copyright}</Copyright>
        </ThemeProvider>
      </Footer>
    </Container>
  );
};

export default HomeScreen;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Footer = styled.div`
  width: 100%;
  margin-top: 100px;
  padding: 10px 0;
  background-color: ${EColors.BLACK + EOpacity.OPAC_90};
  box-shadow: 0px 0px 10px 10px ${EColors.BLACK + EOpacity.OPAC_90};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Copyright = styled.p`
  font-size: 14px;
  color: ${EColors.GREY};
  padding-top: 20px;
`;

const ContactsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ theme }) => theme.justify};
  flex-wrap: wrap;
  justify-content: center;
`;
