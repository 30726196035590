import ky from 'ky';
import { Gallery, Response } from './types/generalTypes';

export enum EAPIRoutes {
  LOGIN = 'user/login',
  CHANGE_PASS = 'user/change-password',
  GET_ADMIN_GALLERY = 'admin/gallery/',
  ADD_GALLERY = 'admin/gallery/new',
  UPDATE_GALLERY = 'admin/gallery/:id/update',
  REMOVE_GALLERY = 'admin/gallery/:id/remove',
  UPLOAD_IMAGE = 'admin/gallery/upload',
  REMOVE_IMAGE = 'admin/gallery/image/:id/remove',
  GET_GALLERY = 'gallery/',
}

interface LoginResp {
  data: {
    token: string;
  };
}

// const DEV_URL = 'http://localhost:4000/api/'
// export const BASE_URL = DEV_URL;
const PROD_URL = 'https://ovas.lt/api/';
export const BASE_URL = PROD_URL;

class Api {
  private instance = ky.create({ prefixUrl: BASE_URL });

  private getJsonData = (data: unknown) => ({ json: data });

  private getFormData = (data: FormData) => ({ body: data });

  private formatRoute(url: string, params: any): string {
    let q = url;
    for (const param in params) {
      q = q.replace(`:${param}`, params[param as never]);
    }
    return q;
  }

  private post = <T>(route: string, params: unknown) => {
    return this.instance.post(route, this.getJsonData(params)).json<T>();
  };

  private delete = (route: string) => {
    return this.instance.delete(route).json();
  };

  private put = (route: string, params: unknown) => {
    return this.instance.patch(route, this.getJsonData(params)).json();
  };

  public setToken = (token: string) => {
    this.instance = this.instance.extend({
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  public changePass = (params: unknown) => this.post(EAPIRoutes.CHANGE_PASS, params);

  public login = (params: unknown) => this.post<LoginResp>(EAPIRoutes.LOGIN, params);

  public addGallery = (params: unknown) => this.post<any>(EAPIRoutes.ADD_GALLERY, params);

  public updateGallery = ({ _id, ...restParams }: any) =>
    this.put(this.formatRoute(EAPIRoutes.UPDATE_GALLERY, { id: _id }), restParams);

  public removeGallery = (id: string) =>
    this.delete(this.formatRoute(EAPIRoutes.REMOVE_GALLERY, { id }));

  public uploadImage = (params: FormData) =>
    this.instance.post(EAPIRoutes.UPLOAD_IMAGE, { body: params }).json<ImageData>();

  public removeImage = (id: string | number) =>
    this.delete(this.formatRoute(EAPIRoutes.REMOVE_IMAGE, { id }));

  public getAdminGalleryList = () => this.instance.get(EAPIRoutes.GET_ADMIN_GALLERY).json();

  public getGallery = () => this.instance.get(EAPIRoutes.GET_GALLERY).json<Response<Gallery[]>>();
}

const api = new Api();

export { api };
