import { ESize } from '../types/enums';
import { Image } from '../types/generalTypes';
import { EScreenBreakpoints } from '../config/styles';
import { responsiveLayoutTheme } from '../config/themes';

export const generateGalleryItemSize = (i: number) => {
  let size = ESize.LARGE;

  if (i % 2 === 0) {
    size = ESize.MEDIUM;
  }

  if (i % 4 === 0) {
    size = ESize.SMALL;
  }

  return size;
};

const BREAKPOINTS = Object.values(EScreenBreakpoints).filter(
  (k) => typeof k === 'number',
) as EScreenBreakpoints[];

export const findBreakpoint = (last: EScreenBreakpoints = EScreenBreakpoints.DESKTOP) => {
  const width = window.innerWidth >= window.screen.width ? window.screen.width : window.innerWidth;
  return BREAKPOINTS.find((bp) => width >= responsiveLayoutTheme[bp].width) || last;
};

export const prepareImagesState = (files: File[]) => {
  let images = [];
  for (let i = 0; i < files.length; i++) {
    images.push({
      categories: [],
      _id: i,
      loading: true,
      fileName: files[i].name,
    });
  }
  return images;
};

export const uniqueID = () => {
  return Math.random().toString(36).slice(2);
};

export const prepareRequestImages = (images: Image[]) => {
  return images.map(({ _id, categories, filterType }) => ({
    _id,
    categories,
    filterType,
  }));
};
