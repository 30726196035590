import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import { Form } from '../../../types/generalTypes';
import { EChangePassKeys } from '../../../types/enums';
import { CHANGE_PASS_FORM, FORM_CONFIG } from '../../../config/staticData';
import { strings } from '../../../config/strings';
import { api } from '../../../Api';
import { AdminContext } from '../../../providers/AdminProvider';

const initialInput = {
  [EChangePassKeys.OLD_PASSWORD]: '',
  [EChangePassKeys.NEW_PASSWORD]: '',
  [EChangePassKeys.RE_NEW_PASSWORD]: '',
};

const SettingsSegment = () => {
  const [form, setForm] = useState(initialInput);
  const { logout } = useContext(AdminContext);

  const inputHandler = ({ target }: React.ChangeEvent<any>) => {
    setForm((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const clickHandler = async () => {
    const { reNewPassword, newPassword, oldPassword } = form;

    if (reNewPassword.length > 0 && newPassword.length > 0 && newPassword === reNewPassword) {
      try {
        await api.changePass({ oldPassword, newPassword });
        // logout();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const renderTextField = ({ name, label }: Form<EChangePassKeys>) => {
    return (
      <TextField
        label={label}
        value={form[name]}
        name={name}
        onChange={inputHandler}
        type={'password'}
        {...FORM_CONFIG}
      />
    );
  };

  return (
    <Container>
      <Wrapper>
        <Title>{strings.settings.changePass.title}</Title>
        {CHANGE_PASS_FORM.map(renderTextField)}
        <ButtonWrapper>
          <Button size={'medium'} variant="outlined" color="primary" onClick={clickHandler}>
            {strings.settings.changePass.changeButton}
          </Button>
        </ButtonWrapper>
      </Wrapper>
      {/* <Wrapper>
        <Title>{strings.settings.newUser.title}</Title>
        {CHANGE_PASS_FORM.map(renderTextField)}
        <ButtonWrapper>
          <Button size={'medium'} variant="outlined" color="primary" onClick={clickHandler}>
            {strings.settings.changePass.changeButton}
          </Button>
        </ButtonWrapper>
      </Wrapper> */}
    </Container>
  );
};

export default SettingsSegment;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
`;

const Wrapper = styled.div`
  width: 45%;
  height: 100%;
  padding: 16px;
`;

const Title = styled.div`
  font-size: 24px;
  padding: 15px 0;
`;

const ButtonWrapper = styled.div`
  padding-top: 20px;
`;
