import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import { ResponsiveLayoutProvider } from './providers/ResponsiveLayoutProvider';
import { GalleryModalProvider } from './providers/GalleryProvider';
import { GlobalFonts } from './config/styles';
import AppRoutes from './routes/AppRoutes';

const GlobalStyle = createGlobalStyle`
  ${reset}
  /* other styles */
`;

function App() {
  return (
    <ResponsiveLayoutProvider>
      <GalleryModalProvider>
        <GlobalFonts />
        <GlobalStyle />
        <AppRoutes />
      </GalleryModalProvider>
    </ResponsiveLayoutProvider>
  );
}

export default App;
